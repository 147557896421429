<template>
  <div class="page">
    <!--pages/aboutus/aboutus.wxml-->
    <div class="banner">
      <img class="down-img" mode="widthFix" src="~images/logo.png">
    </div>
  
    <div class="desc">
		*『途够礼物』成立于2018年7月，是一家专注于旅游在途中场景式营销旅游伴手礼移动平台；是北京市旅游行业协会地接专业委员会战略合作伙伴；是北京礼物、非遗传承人宣传推广渠道之一；
    </div>
    <div class="desc">
		*『途够心选』创建于2020年2月，疫情期间北京中远国旅控股投资专为旅游从业者打造旅游行业的社群交流平台，集旅游商品、老字号、非遗传承、特产、原产地严选、大厂直采为一体的高品质巨便宜直播带货平台打造100位旅游主播矩阵的模式，为景区目的地旅游开启云旅游新概念，玩转旅游私域流量。引领旅游行业直播发展趋势，成为云旅游覆盖百万直客私域直播第一梯队！
    </div>
    <div class="desc">
		*『途够养生』，后疫情时代，紧跟时代新亮点将业务重心专为养生产业，利用自身资源与616年老字号鹤年堂强强联合，多方赋能，扩展渠道，整合资源、服务升级，共同打造“生命赋能工程” 新模式，尽享616年鹤年堂中医药养生精粹带来的“生命赋能”新体验！
    </div>
  </div>
</template>

<script>
  export default {
    name: 'aboutus',
    data() {
      return {
      }
    },
    methods: {
    }
  };
</script>

<style lang="scss" scoped>
  .banner {
    position: relative;
    margin-bottom: 118px;
    height: 300px;
    background-image: linear-gradient(-54deg, #FF9D10 0%, #FCCE09 96%);
  }
  .down-img {
    position: absolute;
    left: 50%;
    bottom: -80px;
    margin-left: -80px;
    width: 160px;
  }
  .desc {
    padding: 32px;
    line-height: 46px;
    text-indent: 6px;
    font-size: 28px;
    color: #333333;
  }

</style>
